import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import $ from "jquery";
import useGet from '../Hooks/UseGet';

const Header = () => {
    const { data: general } = useGet(`/general`);
    const page = localStorage.getItem('page');
    const [active, setActive] = useState(false);
    useEffect(() => {
        if (page) {
            $.each($('.nav li'), function (index, value) {
                if (value.id === page) {
                    $(this).addClass('active');
                } else {
                    $(this).removeClass('active');
                }
            });
        } else {
            $('#home').addClass('active');
        }

        $(".sub-menu, .mega-menu").parent('li').addClass('has-child');
        $("<div class='fa fa-angle-right submenu-toogle'></div>").insertAfter(".has-child > a");
        $('.has-child a+.submenu-toogle').on('click', function (ev) {
            $(this).parent().siblings(".has-child ").children(".sub-menu, .mega-menu").slideUp(500, function () {
                $(this).parent().removeClass('nav-active');
            });
            $(this).next($('.sub-menu, .mega-menu ')).slideToggle(500, function () {
                $(this).parent().toggleClass('nav-active');
            });
            ev.stopPropagation();
        });

        $('#mobile-side-drawer').on('click', function () {
            $('.mobile-sider-drawer-menu').toggleClass('active');
        });

        window.$('.sticky_column').theiaStickySidebar({
            additionalMarginTop: 100
        });

        $('.loading-area').fadeOut(1000)

        $(window).on('scroll', function () {
            var scroll = $(window).scrollTop();
            if (scroll >= 100) {
                $(".main-bar").addClass("color-fill");
            } else {
                (scroll = 100); $(".main-bar").removeClass("color-fill");
            }
        });
    }, [page]);


    function handleClick() {
        setActive(!active);
    }
    return (
        <header className={`header-style2 site-header mobile-sider-drawer-menu full-navbar  ${active ? 'active' : ''}`}>
            <div className="sticky-header main-bar-wraper navbar-expand-lg">
                <div className="main-bar">
                    <div className="container clearfix">
                        <div className="logo-header">
                            <div className="logo-header-inner logo-header-one">
                                <a href="/">
                                    <img src={`../../${general && general[0].logo}`} alt="logo" />
                                </a>
                            </div>
                        </div>
                        <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggler collapsed"
                            onClick={handleClick} aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar icon-bar-first" />
                            <span className="icon-bar icon-bar-two" />
                            <span className="icon-bar icon-bar-three" />
                        </button>
                        <div className="extra-nav">
                            <div className="extra-cell">
                                <ul className="list-unstyled social-bx d-flex flex-wrap align-content-center text-white ">
                                    <li><a href={general && general[0].fb_link} target="_blank" rel="noopener noreferrer"><i className="flaticon-facebook" /></a></li>
                                    <li><a href={general && general[0].ig_link} target="_blank" rel="noopener noreferrer"><i className="flaticon-instagram" /></a></li>
                                    <li><a href={general && general[0].twitter_link} target="_blank" rel="noopener noreferrer"><i className="flaticon-twitter" /></a></li>
                                    <li><a href={general && general[0].linkedin_link} target="_blank" rel="noopener noreferrer"><i className="flaticon-linkedin" /></a></li>
                                    <li><a href={general && general[0].youtube_link} target="_blank" rel="noopener noreferrer"><i className="flaticon-youtube" /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                            <ul className="nav navbar-nav">
                                <li id="home" onClick={() => { localStorage.setItem('page', 'home'); window.scrollTo(0, 0); }}>
                                    <Link to="/">Home</Link>
                                </li>
                                <li id="products" onClick={() => { localStorage.setItem('page', 'products'); window.scrollTo(0, 0); }}>
                                    <Link to="/products">Products</Link>
                                </li>
                                <li id="service" onClick={() => { localStorage.setItem('page', 'service'); window.scrollTo(0, 0); }}>
                                    <Link to="/service">Service</Link>
                                </li>
                                <li id="blog" onClick={() => { localStorage.setItem('page', 'blog'); window.scrollTo(0, 0); }}>
                                    <Link to="/blog">Blog</Link>
                                </li>
                                <li id="staffs" onClick={() => { localStorage.setItem('page', 'staffs'); window.scrollTo(0, 0); }}>
                                    <Link to="/staffs">Staff</Link>
                                </li>
                                <li id="about-us" onClick={() => { localStorage.setItem('page', 'about-us'); window.scrollTo(0, 0); }}>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li id="contact-us" onClick={() => { localStorage.setItem('page', 'contact-us'); window.scrollTo(0, 0); }}>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;