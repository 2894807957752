import useGet from '../Hooks/UseGet';
const ContactUs = () => {
    const { data: data } = useGet('/contact');
    return (<>
        <div>
            <div className="full-landing-image sx-bnr-inr overlay-wraper  bg-top-center" style={{ backgroundImage: 'url(../../../assets/images/banner/bnr-6.jpg)' }}>
                <div className="overlay-main bg-white opacity-0" />
                <div className="container">
                    <div className="sx-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <span className="sx-bnr-sm-title">we are Dedicated</span>
                                <h2 className="sx-title">Contact Us.</h2>
                            </div>
                            <div>
                                <ul className="sx-breadcrumb breadcrumb-style-2">
                                    <li><a href="index.html">Home</a></li>
                                    <li>Contact Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sx-bnr-outline-text">
                    <h1> It Solution</h1>
                </div>
            </div>
            <div className="section-full p-t110 p-b80 sx-bg-white sx-ourteam-outer ">
                <div className="container">
                    <div className="section-content">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12  m-b30">
                                <div className="contact-info">
                                    <div className="section-head left">
                                        <div className="sx-head-s-title">Contact Us</div>
                                        <div className="sx-head-l-title">
                                            <h2 className="sx-title">Contact Our Office.</h2>
                                        </div>
                                    </div>
                                    <div className="row sx-contact-form-icon-bx">
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-telephone" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a href="javascript:;" className="sx-icon-bx-title-info">Contact Phone</a>
                                                    <a href="javascript:;" className="sx-icon-bx-title">{data && data[0].phone_1}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-email-2" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a href="javascript:;" className="sx-icon-bx-title-info">Contact Mail</a>
                                                    <a href="javascript:;" className="sx-icon-bx-title">{data && data[0].email}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-map-location" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a href="javascript:;" className="sx-icon-bx-title-info">Contact Location</a>
                                                    <a href="javascript:;" className="sx-icon-bx-title">{data && data[0].location}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b30">
                                            <div className="sx-icon-bx-5">
                                                <div className="sx-media">
                                                    <i className="flaticon-clock" />
                                                </div>
                                                <div className="sx-icon-bx-info">
                                                    <a href="javascript:;" className="sx-icon-bx-title-info">Office Time</a>
                                                    <a href="javascript:;" className="sx-icon-bx-title">
                                                        {data && data[0].office_time_from}
                                                    </a>
                                                    <a href="javascript:;" className="sx-icon-bx-title">
                                                        {data && data[0].office_time_to}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 m-b30">
                                <div className="contact-home1-right">
                                    <div className="contact-home-1-form sx-bg-light">
                                        <h4 className="sx-title">Get In Touch</h4>
                                        <form className="cons-contact-form2 form-transparent">
                                            <div className="input input-animate">
                                                <input type="text" name="username" id="name" required placeholder="Your Name" />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <input type="email" name="email" id="email" required placeholder="Your Email" />
                                                <span className="spin" />
                                            </div>
                                            <div className="input input-animate">
                                                <input type="text" name="phone" id="Phone" required placeholder="Phone Number" />
                                                <span className="spin" />
                                            </div>
                                            <div className="input textarea input-animate">
                                                <textarea name="message" id="message" required placeholder="Your Message" defaultValue={""} />
                                                <span className="spin" />
                                            </div>
                                            <div className="sx-btn-center text-center p-t10">
                                                <button type="submit" className="site-button sx-btn-primary icon sx-btn-lg">
                                                    <i className="fa  fa-long-arrow-right" />
                                                    Send Message
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gmap-outline map-grayscle" dangerouslySetInnerHTML={{ __html: data && data[0].map_embed }} />
        </div>

    </>);
}

export default ContactUs;