import useGet from '../Hooks/UseGet';
const Staffs = () => {
    const { data: data } = useGet('/staff');
    return (
        <>
            <div className="full-landing-image sx-bnr-inr overlay-wraper  bg-top-center" style={{ backgroundImage: 'url(../../../assets/images/banner/bnr-2.jpg)' }}>
                <div className="overlay-main bg-white opacity-0" />
                <div className="container">
                    <div className="sx-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <span className="sx-bnr-sm-title">we are Dedicated</span>
                                <h2 className="sx-title">Team Member.</h2>
                            </div>
                            <div>
                                <ul className="sx-breadcrumb breadcrumb-style-2">
                                    <li><a href="index.html">Home</a></li>
                                    <li>Our Team</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sx-bnr-outline-text">
                    <h1> It Solution</h1>
                </div>
            </div>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-ourteam-outer ">
                <div className="container">
                    <div className="section-head center">
                        <div className="sx-head-s-title">Our Team</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">IT Solution Team Members</h2>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row sx-our-team-section">
                            {data && data.map((item, index) => {
                                return (
                                    <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                                        <div className="our-team-2">
                                            <div className="profile-image">
                                                <img src={item.image} alt />
                                                <div className="icons">
                                                    <a href={item.contact} target="_blank" rel="noopener noreferrer" >
                                                        <i className="fa fa-paper-plane-o" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="figcaption">
                                                <p>{item.position}</p>
                                                <h4 className="name">{item.full_name}</h4>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Staffs;