import { Link } from 'react-router-dom';
import useGet from '../Hooks/UseGet';
const Blog = () => {
    const { data: data } = useGet('/view_blogs');
    return (
        <div className="page-content">
            <div className="full-landing-image sx-bnr-inr overlay-wraper  bg-top-center" style={{ backgroundImage: 'url(../../assets/images/banner/bnr-1.jpg)' }}>
                <div className="overlay-main bg-white opacity-0" />
                <div className="container">
                    <div className="sx-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <span className="sx-bnr-sm-title">we are Dedicated</span>
                                <h2 className="sx-title">Blog Page</h2>
                            </div>
                            <div>
                                <ul className="sx-breadcrumb breadcrumb-style-2">
                                    <li><a href="/">Home</a></li>
                                    <li>Blog page</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sx-bnr-outline-text">
                    <h1> It Solution</h1>
                </div>
            </div>
            <div className="section-full p-t100 p-b0 bg-white mobile-page-padding">
                <div className="container">
                    <div className="mfp-gallery news-grid clearfix row mb-7">
                        {data && data.map((item, index) => {
                            return (
                                <div className="col-md-4">
                                    <div className="sx-latest-post-st-1">
                                        <div className="sx-post-media sx-img-effect img-reflection">
                                            <Link to={`/blog/${item.id}`}>
                                                <img src={item.image} alt />
                                            </Link>
                                        </div>
                                        <div className="sx-post-info p-t30">
                                            <div className="sx-post-meta ">
                                                <ul>
                                                    <li className="post-date">
                                                        <Link to={`/blog/${item.id}`}>
                                                            <i className="fa  fa-calendar" />
                                                            {new Date(item.created).toDateString()}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="sx-post-title ">
                                                <h4 className="post-title">
                                                    <Link to={`/blog/${item.id}`}>
                                                        {item.title}
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="sx-post-readmore">
                                                <Link to={`/blog/${item.id}`} title="READ MORE" rel="bookmark" className="site-button icon">
                                                    <i className="fa fa-long-arrow-right" />Read More
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Blog;