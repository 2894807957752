import { useRouteError } from "react-router-dom";
export default function ErrorPage() {
    const error = useRouteError();
    return (
        <div className="main-wrapper">
            <div className="page-wrapper full-page">
                <div className="page-content d-flex align-items-center justify-content-center">
                    <div className="row w-100 mx-0 auth-page">
                        <div className="col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center">
                            {/*?xml version="1.0" encoding="utf-8"?*/}
                            {/* Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  */}
                            <svg version="1.1" id="OBJECTS" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 485.5 186.9" style={{ enableBackground: 'new 0 0 485.5 186.9' }} xmlSpace="preserve">
                                <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill:#4C60A5;}\n\t.st1{fill:#637ABE;}\n\t.st2{fill:#82A7F7;}\n\t.st3{fill:#A0ABE9;}\n\t.st4{fill:#697DD1;}\n\t.st5{fill:#617FC8;}\n\t.st6{fill:#7AA2F7;}\n\t.st7{fill:#D5E5FC;}\n\t.st8{fill:#FB6F6E;}\n\t.st9{fill:#E0EEFB;}\n\t.st10{fill:#FFFFFF;}\n\t.st11{fill:#F3A9B8;}\n\t.st12{opacity:0.7;fill:#E0EEFB;}\n\t.st13{opacity:0.5;fill:#E0EEFB;}\n\t.st14{fill:none;stroke:#2E3154;stroke-width:0.5;stroke-miterlimit:10;}\n\t.st15{fill:none;stroke:#2E3154;stroke-width:0.5;stroke-miterlimit:10;stroke-dasharray:1.0014,1.0014;}\n\t.st16{fill:#2E3154;}\n\t.st17{fill:#5A75C2;}\n\t.st18{fill:#354B87;}\n" }} />
                                <g>
                                    <g>
                                        <path className="st0" d="M212.2,77c-4.8-1.4-8.2,0.1-10.6,3.2l8.6,8.6L212.2,77z" />
                                        <path className="st0" d="M200.9,148.3l6.5-2c0,0,3.8-8.8,7.4-20.1l-10.3,1.3L200.9,148.3z" />
                                        <polygon className="st0" points="197.3,124.3 197.3,149.4 199.8,148.7 203.5,127.4 		" />
                                        <path className="st0" d="M207.7,103l2.2-13l-8.9-9c-1.8,2.8-3,6.6-3.7,10.8v3.4L207.7,103z" />
                                        <path className="st0" d="M204.7,126.5l10.5-1.3c1.1-3.4,2.1-7,3-10.7l-11.7,1.6L204.7,126.5z" />
                                        <polygon className="st0" points="207.5,104.2 197.3,96.5 197.3,110.1 205.7,114.8 		" />
                                        <path className="st0" d="M220,85.5c-1.1-3.9-3.3-6.8-6.8-8.2l-2,11.8L220,85.5z" />
                                        <path className="st0" d="M208.7,103.3l12-2.5c0.6-5.3,0.6-10.2-0.4-14.3l-9.3,3.8L208.7,103.3z" />
                                        <polygon className="st0" points="188.1,137.9 196.3,130.6 196.3,115.8 185.1,125.5 		" />
                                        <path className="st0" d="M206.7,115l11.7-1.6c0.9-3.9,1.7-7.8,2.2-11.6l-12.1,2.5L206.7,115z" />
                                        <polygon className="st0" points="205.5,115.9 197.3,111.3 197.3,123.2 203.7,126.4 		" />
                                        <path className="st0" d="M181.1,113.6l-23.4-1.4c1,4.6,2.2,9.2,3.7,13.6l22.4-0.9L181.1,113.6z" />
                                        <path className="st0" d="M177.5,98.4l-22-3c0.3,5.1,1,10.4,2.1,15.7l23.3,1.4L177.5,98.4z" />
                                        <path className="st0" d="M168.6,61.8c-6.2,1.5-10.1,7.3-11.9,15.5l16.8,4.6L168.6,61.8z" />
                                        <path className="st0" d="M178.1,96.9l15.7-16.4c-1.4-3.9-3.1-7.5-5.4-10.5l-13.8,12.7L178.1,96.9z" />
                                        <path className="st0" d="M184.1,125.8l-22.3,0.9c1.5,4.3,3.2,8.5,5.1,12.4l20.3-0.8L184.1,125.8z" />
                                        <path className="st0" d="M167.4,140.1c2.9,5.9,6.3,11.2,10.1,15.4l12.9-3.9l-3-12.2L167.4,140.1z" />
                                        <polygon className="st0" points="184.8,124.4 196.3,114.5 196.3,101.1 182.1,113.3 		" />
                                        <path className="st0" d="M181.8,112.2l14.5-12.4V90c-0.5-2.9-1.2-5.7-2.1-8.4l-15.8,16.4L181.8,112.2z" />
                                        <polygon className="st0" points="188.4,139 191.3,151.3 196.3,149.7 196.3,132 		" />
                                        <path className="st0" d="M187.9,69.3c-3.3-4.1-7.7-6.9-13.4-7.7c-1.8-0.2-3.4-0.2-4.9,0l4.9,20L187.9,69.3z" />
                                        <path className="st0" d="M173.7,83l-17.3-4.7c-1,4.8-1.3,10.3-1,16.2l21.8,2.9L173.7,83z" />
                                        <polygon className="st1" points="188,139.3 187.4,139.3 190.4,151.5 191.3,151.3 188.4,139 		" />
                                        <path className="st1" d="M174,82l0.4-0.4l-4.9-20c-0.3,0.1-0.7,0.1-1,0.2l4.9,20.1L174,82z" />
                                        <polygon className="st1" points="188.1,137.9 185.1,125.5 184.7,125.8 184.1,125.8 187.2,138.3 187.6,138.3 		" />
                                        <polygon className="st1" points="184.8,124.4 182.1,113.3 181.7,113.6 181.1,113.6 183.9,124.8 184.3,124.8 		" />
                                        <polygon className="st1" points="178.1,96.9 174.7,82.7 174.3,83.1 173.7,83 177.2,97.3 177.7,97.4 		" />
                                        <polygon className="st1" points="181.8,112.2 178.4,98.1 178,98.4 177.5,98.4 180.9,112.5 181.4,112.6 		" />
                                        <polygon className="st1" points="207.5,104.2 205.7,114.8 206.2,115.1 206.7,115 208.5,104.4 208,104.5 		" />
                                        <polygon className="st1" points="205.5,115.9 203.7,126.4 204.2,126.6 204.7,126.5 206.5,116 206,116.1 		" />
                                        <path className="st1" d="M210.6,89.3l0.5-0.2l2-11.8c-0.3-0.1-0.6-0.2-0.9-0.3c0,0,0,0,0,0l-2,11.9L210.6,89.3z" />
                                        <polygon className="st1" points="204,127.6 203.5,127.4 199.8,148.7 200.9,148.3 204.5,127.6 		" />
                                        <polygon className="st1" points="210,90.1 207.7,103 208.2,103.4 208.7,103.3 211,90.3 210.4,90.5 		" />
                                        <path className="st1" d="M173.4,81.8l-16.8-4.6c-0.1,0.3-0.1,0.7-0.2,1l17.3,4.7L173.4,81.8z" />
                                        <path className="st1" d="M174.7,82.7l13.8-12.7c-0.2-0.3-0.4-0.5-0.6-0.8l-13.4,12.3L174.7,82.7z" />
                                        <polygon className="st1" points="174.7,82.7 174.4,81.6 174,82 173.4,81.8 173.7,83 174.3,83.1 		" />
                                        <path className="st1" d="M177.2,97.3l-21.8-2.9c0,0.3,0,0.7,0.1,1l22,3L177.2,97.3z" />
                                        <path className="st1" d="M178.4,98.1l15.8-16.4c-0.1-0.4-0.2-0.7-0.4-1.1l-15.7,16.4L178.4,98.1z" />
                                        <polygon className="st1" points="178.4,98.1 178.1,96.9 177.7,97.4 177.2,97.3 177.5,98.4 178,98.4 		" />
                                        <polygon className="st1" points="182.1,113.3 196.3,101.1 196.3,99.8 181.8,112.2 		" />
                                        <path className="st1" d="M180.9,112.5l-23.3-1.4c0.1,0.3,0.1,0.7,0.2,1l23.4,1.4L180.9,112.5z" />
                                        <polygon className="st1" points="182.1,113.3 181.8,112.2 181.4,112.6 180.9,112.5 181.1,113.6 181.7,113.6 		" />
                                        <path className="st1" d="M196.6,124l0.4-0.9l0.3,0.1v-11.9l-0.7-0.4l0.5-0.9l0.3,0.1V96.5l-0.8-0.6l0.6-0.8l0.2,0.2v-3.4
			c-0.1,0.7-0.2,1.4-0.3,2.2c-0.2-1.3-0.4-2.7-0.7-4v9.8l0.2-0.1l0.7,0.8l-0.8,0.7v13.4l0.2-0.1l0.7,0.8l-0.8,0.7v14.8l0.2-0.1
			l0.7,0.8l-0.8,0.7v17.8l1-0.3v-25.1L196.6,124z" />
                                        <polygon className="st1" points="197.1,100.4 196.5,99.6 196.3,99.8 196.3,101.1 		" />
                                        <path className="st1" d="M211.2,89.1l-0.2,1.2l9.3-3.8c-0.1-0.3-0.2-0.7-0.3-1L211.2,89.1z" />
                                        <path className="st1" d="M210.2,88.9l-8.6-8.6c-0.2,0.3-0.4,0.5-0.6,0.8l8.9,9L210.2,88.9z" />
                                        <polygon className="st1" points="210.2,88.9 210,90.1 210.4,90.5 211,90.3 211.2,89.1 210.6,89.3 		" />
                                        <path className="st1" d="M208.5,104.4l12.1-2.5c0-0.4,0.1-0.7,0.1-1l-12,2.5L208.5,104.4z" />
                                        <polygon className="st1" points="207.7,103 197.3,95.2 197.3,96.5 207.5,104.2 		" />
                                        <polygon className="st1" points="207.7,103 207.5,104.2 208,104.5 208.5,104.4 208.7,103.3 208.2,103.4 		" />
                                        <polygon className="st1" points="197.1,95.1 196.5,95.9 197.3,96.5 197.3,95.2 		" />
                                        <polygon className="st1" points="205.7,114.8 197.3,110.1 197.3,111.3 205.5,115.9 		" />
                                        <path className="st1" d="M206.5,116l11.7-1.6c0.1-0.3,0.2-0.7,0.3-1l-11.7,1.6L206.5,116z" />
                                        <polygon className="st1" points="205.7,114.8 205.5,115.9 206,116.1 206.5,116 206.7,115 206.2,115.1 		" />
                                        <polygon className="st1" points="197.1,110 196.6,110.9 197.3,111.3 197.3,110.1 		" />
                                        <path className="st1" d="M204.5,127.6l10.3-1.3c0.1-0.3,0.2-0.7,0.3-1.1l-10.5,1.3L204.5,127.6z" />
                                        <polygon className="st1" points="197.3,123.2 197.3,124.3 203.5,127.4 203.7,126.4 		" />
                                        <polygon className="st1" points="203.7,126.4 203.5,127.4 204,127.6 204.5,127.6 204.7,126.5 204.2,126.6 		" />
                                        <polygon className="st1" points="197,123.1 196.6,124 197.3,124.3 197.3,123.2 		" />
                                        <path className="st1" d="M183.9,124.8l-22.4,0.9c0.1,0.3,0.2,0.7,0.3,1l22.3-0.9L183.9,124.8z" />
                                        <polygon className="st1" points="185.1,125.5 196.3,115.8 196.3,114.5 184.8,124.4 		" />
                                        <polygon className="st1" points="185.1,125.5 184.8,124.4 184.3,124.8 183.9,124.8 184.1,125.8 184.7,125.8 		" />
                                        <polygon className="st1" points="197.1,115.1 196.5,114.3 196.3,114.5 196.3,115.8 		" />
                                        <polygon className="st1" points="188.4,139 196.3,132 196.3,130.6 188.1,137.9 		" />
                                        <path className="st1" d="M187.2,138.3l-20.3,0.8c0.2,0.3,0.3,0.7,0.5,1l20-0.8L187.2,138.3z" />
                                        <polygon className="st1" points="187.6,138.3 187.2,138.3 187.4,139.3 188,139.3 188.4,139 188.1,137.9 		" />
                                        <polygon className="st1" points="197.2,131.2 196.5,130.5 196.3,130.6 196.3,132 		" />
                                    </g>
                                    <path className="st2" d="M264.4,174.8c0,0-7-47.6-49.7-54.9c-42.6-7.3-37.5-67.6-95.7-65.2c-58.3,2.4-46.3,58.4-69.4,71
		c-23.2,12.6-33.9,49-33.9,49H264.4z" />
                                    <path className="st2" d="M257.1,174.8c0,0,0-38.6,45.7-52.2c45.7-13.6,34.8-84.3,92-73.5c57.2,10.9,29.4,83.3,51.7,99.1
		c22.3,15.8,17.4,26.6,17.4,26.6H257.1z" />
                                    <g>
                                        <path className="st3" d="M33.2,175l1.7,0L3.6,62.7C-6.2,66.6,33.2,175,33.2,175z" />
                                        <path className="st3" d="M44.5,175.2l29.9-85.4c-7.7-0.4-17.7,33-22.4,50.3c-1.6,6.3-2.6,10.1-2.6,10.1s1-4.1,2.6-10.1
			C58.2,115.1,73,52,64.2,46L40.9,175.1L44.5,175.2z" />
                                        <path className="st3" d="M37.2,175.1L34.5,22.5C23.7,29.8,33.2,155,33.2,155S14.8,65.1,4.6,62.6L35.9,175L37.2,175.1z" />
                                        <path className="st3" d="M52,140.1c-1.6,6-2.6,10.1-2.6,10.1S50.4,146.4,52,140.1z" />
                                        <path className="st3" d="M39.9,175.1L63.2,45.6C51,43.5,39.8,155,39.8,155s8.1-132.8-4.3-132.8c0,0,0,0,0,0l2.7,152.9L39.9,175.1z" />
                                        <path className="st3" d="M48.9,175.3c0,0,37.2-79.5,26.7-85.1c-0.1,0-0.1-0.1-0.2-0.1l-29.8,85.2L48.9,175.3z" />
                                        <path className="st4" d="M35.9,175L4.6,62.6c-0.3-0.1-0.6-0.1-1,0c0,0,0,0-0.1,0L34.9,175L35.9,175z" />
                                        <path className="st4" d="M38.2,175.1L35.5,22.2c-0.3,0-0.7,0.1-1,0.3l2.7,152.6L38.2,175.1z" />
                                        <path className="st4" d="M40.9,175.1L64.2,46c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.3-0.1-0.4-0.1L39.9,175.1L40.9,175.1z" />
                                        <path className="st4" d="M45.6,175.2L75.4,90c-0.3-0.1-0.6-0.2-1-0.2l-29.9,85.4L45.6,175.2z" />
                                    </g>
                                    <g>
                                        <path className="st3" d="M439.7,175.1l23.4-129.5C450.8,43.5,439.7,155,439.7,155s8.1-132.8-4.3-132.8c0,0,0,0,0,0l2.7,152.9
			L439.7,175.1z" />
                                        <path className="st3" d="M444.3,175.2l29.9-85.4c-7.7-0.4-17.7,33-22.4,50.3c-1.6,6.3-2.6,10.1-2.6,10.1s1-4.1,2.6-10.1
			c6.3-25,21.1-88.2,12.2-94.1l-23.3,129.2L444.3,175.2z" />
                                        <path className="st3" d="M451.8,140.1c-1.6,6-2.6,10.1-2.6,10.1S450.2,146.4,451.8,140.1z" />
                                        <path className="st3" d="M448.7,175.3c0,0,37.2-79.5,26.7-85.1c-0.1,0-0.1-0.1-0.2-0.1l-29.8,85.2L448.7,175.3z" />
                                        <path className="st3" d="M433,175l1.7,0L403.4,62.7C393.6,66.6,433,175,433,175z" />
                                        <path className="st3" d="M437,175.1l-2.7-152.6C423.6,29.8,433,155,433,155s-18.3-89.9-28.6-92.3L435.8,175L437,175.1z" />
                                        <path className="st4" d="M435.8,175L404.4,62.6c-0.3-0.1-0.6-0.1-1,0c0,0,0,0-0.1,0L434.7,175L435.8,175z" />
                                        <path className="st4" d="M438,175.1l-2.7-152.9c-0.3,0-0.7,0.1-1,0.3l2.7,152.6L438,175.1z" />
                                        <path className="st4" d="M440.7,175.1L464,46c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.3-0.1-0.4-0.1l-23.4,129.5L440.7,175.1z" />
                                        <path className="st4" d="M445.4,175.2L475.2,90c-0.3-0.1-0.6-0.2-1-0.2l-29.9,85.4L445.4,175.2z" />
                                    </g>
                                    <g>
                                        <path className="st5" d="M70.1,72.4l24.2,101.8l1.9,0l5.9-29.9C102.4,132.2,100.4,97.3,70.1,72.4z" />
                                        <path className="st5" d="M69.7,174.4l10.7-0.1l-44.3-50C47.1,163.8,69.7,174.4,69.7,174.4z" />
                                        <path className="st5" d="M36.3,123.1l45.4,51.2l2.8,0l-14-28.7C65.2,137.4,54,124.4,36.3,123.1z" />
                                        <path className="st5" d="M70.7,143.7l14.9,30.6l7.6-0.1l-24-101C58.1,100.9,65.7,129.9,70.7,143.7z" />
                                        <path className="st5" d="M113.9,174c0,0,34.1-13.2,34.7-65.2l-45.1,65.3L113.9,174z" />
                                        <path className="st5" d="M102.9,145.1l-5.8,29l5.1,0l45.9-66.5C115.8,121.2,105.8,138,102.9,145.1z" />
                                        <path className="st6" d="M35.7,123c0.1,0.4,0.2,0.9,0.4,1.3l44.3,50l1.3,0l-45.4-51.2C36.1,123.1,35.9,123,35.7,123z" />
                                        <path className="st6" d="M69.7,72.1c-0.2,0.4-0.3,0.7-0.4,1.1l24,101l1,0L70.1,72.4C69.9,72.3,69.8,72.2,69.7,72.1z" />
                                        <path className="st6" d="M148.2,107.6l-45.9,66.5l1.2,0l45.1-65.3c0-0.5,0-0.9,0-1.4C148.5,107.5,148.4,107.5,148.2,107.6z" />
                                        <path className="st1" d="M73.7,151.2c0.2,0.5,0.3,0.7,0.3,0.7S73.9,151.6,73.7,151.2z" />
                                        <path className="st1" d="M73.7,151.2c0.2,0.5,0.3,0.7,0.3,0.7S73.9,151.6,73.7,151.2c-0.5-1-1.6-3.1-3.2-5.7l14,28.7l1.1,0l-14.9-30.6
			C72,147.4,73.2,150,73.7,151.2z" />
                                        <path className="st1" d="M101.8,148.6c0,0,0.2-1.6,0.3-4.3l-5.9,29.9l1,0l5.8-29C102.1,147.3,101.8,148.6,101.8,148.6z" />
                                    </g>
                                    <g>
                                        <path className="st0" d="M395.1,152.3l5.3,23.4h2.6l15.5-105.1C391.4,97.9,392.7,135.3,395.1,152.3z" />
                                        <path className="st0" d="M358.5,104.2l39.6,71.5h1.2l-5.9-25.8C388.8,136.5,378.6,114.8,358.5,104.2z" />
                                        <path className="st0" d="M391,175.7h5.9l-39.3-70.9C358.1,159.7,391,175.7,391,175.7z" />
                                        <path className="st0" d="M422,175.7c0,0,27-12.6,39.3-54.4l-43.7,54.4H422z" />
                                        <path className="st0" d="M422.6,153.8l-8.2,21.9h1.9l44.8-55.8C439.8,126.6,428.1,143.3,422.6,153.8z" />
                                        <path className="st0" d="M419.3,71.8L404,175.7h9.4l8.2-22C426.1,137.6,433,102.6,419.3,71.8z" />
                                        <path className="st1" d="M357.7,103.8c0,0.3,0,0.7,0,1l39.3,70.9h1.1l-39.6-71.5C358.2,104.1,358,103.9,357.7,103.8z" />
                                        <path className="st1" d="M418.7,70.4c-0.1,0.1-0.1,0.1-0.2,0.2L403,175.7h1l15.3-103.9C419.1,71.3,418.9,70.9,418.7,70.4z" />
                                        <path className="st1" d="M461.1,120l-44.8,55.8h1.3l43.7-54.4c0.2-0.5,0.3-1,0.5-1.5C461.6,119.8,461.4,119.9,461.1,120z" />
                                        <path className="st1" d="M396.2,159c0.4,1.6,0.6,2.5,0.6,2.5S396.5,160.6,396.2,159c-0.5-2-1.4-5.2-2.7-9.1l5.9,25.8h1l-5.3-23.4
			C395.5,155.1,395.9,157.4,396.2,159z" />
                                        <path className="st1" d="M396.2,159c0.3,1.6,0.6,2.5,0.6,2.5S396.6,160.5,396.2,159z" />
                                        <path className="st1" d="M419.3,161.1c-0.4,1.1-0.6,1.8-0.6,1.8S418.9,162.2,419.3,161.1c0.5-1.5,1.4-4,2.3-7.3l-8.2,22h1.1l8.2-21.9
			C421,156.9,419.9,159.5,419.3,161.1z" />
                                        <path className="st1" d="M419.3,161.1c-0.4,1.1-0.6,1.8-0.6,1.8S418.9,162.3,419.3,161.1z" />
                                    </g>
                                    <path className="st7" d="M242.6,146.4c-169.1,0-240.2,27.9-240.2,27.9S73.5,186,242.6,186s240.2-11.7,240.2-11.7
		S411.7,146.4,242.6,146.4z" />
                                    <g>
                                        <g>
                                            <polygon className="st8" points="333,78.8 327.2,170.1 333.1,170.1 348.4,93.6 357.4,170.1 362.9,170.1 363.2,78.8 			" />
                                        </g>
                                        <g>
                                            <polygon className="st9" points="298.9,51.7 298.8,52.4 294.9,84.5 370,84.5 362.8,52.8 350.9,13.8 323.3,18.8 322.7,18.9 
				322.1,18.8 285.6,11.1 298.7,51.1 			" />
                                            <path className="st10" d="M373.8,87.5L365.7,52L353,10.4l-30.2,5.4l-41.6-8.7L295.9,52l-4.3,35.5H373.8z M322.1,18.8l0.6,0.1l0.6-0.1
				l27.6-5l11.9,39l7.2,31.7h-75.1l3.9-32.2l0.1-0.7l-0.2-0.6l-13.1-40L322.1,18.8z" />
                                        </g>
                                        <path className="st11" d="M348.3,36.7L348.3,36.7c-2.6,0-4.7-2.1-4.7-4.7v-7.8h9.4V32C353,34.6,350.9,36.7,348.3,36.7z" />
                                        <polygon className="st12" points="364.5,48 328.6,50.2 295.9,52 291.6,87.5 373.8,87.5 		" />
                                        <g>
                                            <g>
                                                <g>
                                                    <g>
                                                        <path className="st10" d="M320.4,24.4c-0.9,0-1.8,0.8-2.7,1.9h5.5C322.2,25.2,321.3,24.4,320.4,24.4z" />
                                                    </g>
                                                    <g>
                                                        <path className="st3" d="M326.8,32.7c0,0-1.7-3.9-3.7-6.4h-5.5c-2,2.4-3.7,6.4-3.7,6.4H326.8z" />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <path className="st10" d="M313.6,27.2c-0.6,0-1.2,0.5-1.8,1.3h3.7C314.8,27.7,314.2,27.2,313.6,27.2z" />
                                                    </g>
                                                    <g>
                                                        <path className="st3" d="M317.9,32.7c0,0-1.2-2.6-2.5-4.3h-3.7c-1.3,1.6-2.5,4.3-2.5,4.3H317.9z" />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <path className="st10" d="M327.2,27.2c-0.6,0-1.2,0.5-1.8,1.3h3.7C328.4,27.7,327.7,27.2,327.2,27.2z" />
                                                    </g>
                                                    <g>
                                                        <path className="st3" d="M331.5,32.7c0,0-1.2-2.6-2.5-4.3h-3.7c-1.3,1.6-2.5,4.3-2.5,4.3H331.5z" />
                                                    </g>
                                                </g>
                                            </g>
                                            <path className="st8" d="M328.4,37.1c-2.5,1.3-4.5,3.3-5.9,6.1c-15.5-9.6-28.3-7.7-28.8-7.6l0.6,1.8c0.1,0,12.5-2.2,27.6,7.2
				c-0.4,0.9-0.8,1.9-1.1,3c-3.8,12.9-22.8,14.5-23,14.5l-0.2,1.5c0.7-0.1,13.8-1.2,20.9-9c2.5,1.9,12.9,11.3,6.5,29.9h1.6
				c6.6-19-4.2-28.8-7.1-31c1.2-1.6,2.2-3.4,2.8-5.5c1.4-4.7,3.7-7.9,6.9-9.6c4.6-2.4,11.4-1.9,21.2,1.5l0.5-1.4
				C340.5,35,333.4,34.5,328.4,37.1z" />
                                            <g>
                                                <g>
                                                    <path className="st2" d="M296.4,72.5c0,0,6.1-6.1,12.6-1.9c6.5,4.2,8.2,1.3,11.1,4.2c3.9,3.9,0.3,9.8,0.3,9.8h-25.4L296.4,72.5z" />
                                                </g>
                                            </g>
                                        </g>
                                        <polygon className="st13" points="322.8,15.9 329,52.5 333,87.5 291.6,87.5 295.9,52 281.2,7.1 		" />
                                        <path className="st11" d="M351.6,42.4l9.4,19.2c0.6,1.3,1.8,2.2,3.2,2.6l0,0c3.1,0.7,6.1-1.9,5.8-5.1c-1.1-9.7-9.6-22.9-9.6-22.9
			L351.6,42.4z" />
                                        <g>
                                            <g>
                                                <path className="st14" d="M332.3,77.9c0,0,0-0.2-0.1-0.5" />
                                                <path className="st15" d="M332,76.4c-0.8-2.9-3.8-9-14.1-9.9c-8.8-0.8-13.1-7.3-6.1-17.5c6.7-9.8,9.1-7.9,9.3-16.1" />
                                                <path className="st14" d="M321.1,32.3c0-0.2,0-0.3,0-0.5" />
                                            </g>
                                        </g>
                                        <circle className="st8" cx={321} cy="33.3" r="2.2" />
                                        <path className="st11" d="M344.6,6c0,0-6.9,1.3-6.9,12.5c0,11.2,6.1,10.9,10.5,8.1c4.4-2.8,6.4-9.7,3.4-16.2C348.6,4,344.6,6,344.6,6z
			" />
                                        <path className="st3" d="M347.7,30.4c0,0-10.5-0.7-12.4,11.1c-1.5,9.6,1.7,13.5,1.4,21.6c-0.1,3.9-3.7,11-3.7,15.7
			c10.6,0,30.3,0,30.3,0s-0.8-4.9-3.3-15.7c-0.6-2.8,0.8-6.2,1.9-9.9C367.2,35.5,356,30.4,347.7,30.4z" />
                                        <path className="st8" d="M344.3,17c0,0-7.2,0.7-8-7.8c-0.8-8.5,10.3-11.1,17.1-4.2c1.9-6.4,11.6-4.7,9.8,4.1c4,5.8-0.3,7.4-2.5,7.4
			c0,9.1-5.9,12.4-10.9,10.8C344.8,25.7,344.3,17,344.3,17z" />
                                        <path className="st11" d="M335.7,38.9l-10.5,17.7l-23-3.3l-0.6,3.4c0,0,13.3,8.4,26,7c7.2-0.8,13.9-13.7,16.8-20.2
			c1-2.2,0.3-4.7-1.7-6.1l0,0C340.4,35.8,337.1,36.5,335.7,38.9z" />
                                        <path className="st11" d="M344.3,17c0,0,2.5-0.3,2.5,2.3c0,2.6-3.2,2.9-3.2,2.9L344.3,17z" />
                                        <path className="st16" d="M333.6,74.8c0,0-5.7,11.4-5.7,30.1c7.9,3.8,18.9,2.8,18.9,2.8l1.5-9.9l0.9,10c0,0,9.9,2.4,18.5-2.6
			c0.5-19-5.2-30.4-5.2-30.4S351.7,78.5,333.6,74.8z" />
                                        <path className="st16" d="M326.1,171.2v-12.7h11.3l-2.3,15.8h-16.8C318.4,174.3,320.1,171.2,326.1,171.2z" />
                                        <path className="st16" d="M366,171.2v-12.7h-11.3l2.2,15.8h16.8C373.8,174.3,372.1,171.2,366,171.2z" />
                                        <g>
                                            <path className="st17" d="M349.8,34.4c0,0-9,15.8-9,33.3s36.4,17.2,36.4,0c0-17.2-6.9-33.3-6.9-33.3H349.8z" />
                                            <path className="st0" d="M346.6,41.4h23.8c3.4,0,6.2-2.8,6.2-6.2l0,0c0-3.4-2.8-6.2-6.2-6.2h-23.5c-2.9,0-5.3,2.4-5.3,5.3v2.1
				C341.7,39.1,343.9,41.4,346.6,41.4z" />
                                            <path className="st0" d="M341.7,57.6c0,0-8,16.8,3.7,16.8c11.7,0,6.5-16.8,6.5-16.8H341.7z" />
                                            <path className="st0" d="M359.1,57.6c0,0-7.2,16.8,5.7,16.8c12.9,0,15.2-0.6,15.2-8.2c0-7.7-3.3-8.6-3.3-8.6H359.1z" />
                                            <ellipse className="st18" cx="345.9" cy="35.2" rx="4.6" ry="6.1" />
                                            <path className="st17" d="M353.1,29c0,0,5.6,6.7,0,12.4c2.9,0,2.9,0,2.9,0s5.9-6.2-0.2-12.4" />
                                            <path className="st17" d="M366.7,29c0,0,5.6,6.7,0,12.4c2.9,0,2.9,0,2.9,0s5.9-6.2-0.2-12.4" />
                                        </g>
                                        <path className="st11" d="M303.2,53.7c0,0-3.3-4.2-4.1-3.9c-0.8,0.3,0.8,2.3,0.8,2.3s-4.6-0.7-5.3-0.7c-0.6,0-0.2,1.2,0.9,1.5
			c-1,1,0.2,3.4,3.6,3.6c3.4,0.3,4.3-0.3,4.3-0.3L303.2,53.7z" />
                                    </g>
                                    <path className="st14" d="M353.6,5.9c0,0-2.5,4,2.3,6.2c-1.2,2.5,1.5,5.2,4.5,4.2" />
                                </g>
                            </svg>

                            <h1 className="fw-bolder mb-22 mt-2 tx-47 text-muted">Opps</h1>
                            <h4 className="mb-2 mt-1">
                                Can't find the page you're looking for.
                            </h4>
                            <h6 className="text-muted mb-3 mt-1 text-center">
                                The Reason May be
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-corner-left-down"><polyline points="14 15 9 20 4 15" /><path d="M20 4h-7a4 4 0 0 0-4 4v12" /></svg>
                            </h6>
                            <p><i>{error.statusText || error.message}</i></p>
                            <a className="mt-2" href="../../../">Goto Home </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}