const DFooter = () => {
    return (
        <footer className="footer border-top">
            <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between py-3 small ltr">
                <p className="text-muted mb-1 mb-md-0">© {new Date().getFullYear()} <a href="/" target="_blank" rel="noreferrer">SourceTeach Co</a>.</p>
            </div>
        </footer>
    );
}

export default DFooter;