import { Link } from 'react-router-dom';
import useGet from '../Hooks/UseGet';
const Products = () => {
    const { data: data } = useGet('/view_products');
    return (
        <>
            <div className="full-landing-image sx-bnr-inr overlay-wraper  bg-top-center" style={{ backgroundImage: 'url(../../../assets/images/banner/bnr-4.jpg)' }}>
                <div className="overlay-main bg-white opacity-0" />
                <div className="container">
                    <div className="sx-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <span className="sx-bnr-sm-title">we are Dedicated</span>
                                <h2 className="sx-title">Portfolio Page.</h2>
                            </div>
                            <div>
                                <ul className="sx-breadcrumb breadcrumb-style-2">
                                    <li><a href="index.html">Home</a></li>
                                    <li>Portfolio</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sx-bnr-outline-text">
                    <h1> It Solution</h1>
                </div>
            </div>
            <div className="section-full p-t100 p-b70 sx-bg-light mobile-page-padding">
                <div className="container">
                    <div className="section-content">
                        <div className="large-title-block">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="section-head left">
                                        <div className="sx-head-s-title">Our Case Study</div>
                                        <div className="sx-head-l-title">
                                            <h2 className="sx-title">Our Itodo IT Solution Special Case Studies.</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className=" large-title-info">
                                        <p>Nunc tempor ultrices iaculis. Cras suscipit odio ut neque interdum, sed faucibus lorem feugiat.
                                            tincidunt tellus quis iaculis interdum. Donec a lobortis arcu. Fusce viverra a dolor quis tincidunt. Pellentesque vitae diam sodales nisl dapibus finibus.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mfp-gallery news-grid clearfix row">
                            {data && data.map(item => (
                                <div className="col-lg-6 col-md-6 col-sm-12 m-b30">
                                    <div className="sx-case-study-bx sx-overlay-effect">
                                        <div className="sx-case-media sx-img-overlay">
                                            <Link to={`/product/${item.id}`}><img src={item.image} alt /></Link>
                                            <Link to={`/product/${item.id}`} className="sx-case-link"><i className="flaticon-up-right-arrow" /></Link>
                                        </div>
                                        <div className="sx-case-info">
                                            <div className="sx-s-title">{item.cat_name}</div>
                                            <Link className="sx-l-title" to={`/product/${item.id}`}>
                                                <h2 className="sx-title">{item.title}</h2>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Products;