import { Link, useParams } from 'react-router-dom';
import useGet from '../Hooks/UseGet';
const ProductDetail = () => {
    const { id } = useParams();
    const { data: data } = useGet(`/view_products/${id}`);
    const { data: cat } = useGet(`/categories`);
    const { data: product } = useGet(`/view_products?limit=true`);
    const { data: general } = useGet(`/general`);
    return (
        <div className="page-content">
            <div className="full-landing-image sx-bnr-inr overlay-wraper  bg-top-center" style={{ backgroundImage: 'url(../../assets/images/banner/bnr-10.jpg)' }}>
                <div className="overlay-main bg-white opacity-0" />
                <div className="container">
                    <div className="sx-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <span className="sx-bnr-sm-title">we are Dedicated</span>
                                <h2 className="sx-title">Product detail.</h2>
                            </div>
                            <div>
                                <ul className="sx-breadcrumb breadcrumb-style-2">
                                    <li><a href="index.html">Home</a></li>
                                    <li>Product Detail</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sx-bnr-outline-text">
                    <h1> It Solution</h1>
                </div>
            </div>
            <div className="section-full p-t100 p-b70 mobile-page-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12">
                            <div className="product-single-space">
                                <div className="sx-post-media sx-img-effect img-reflection m-b30">
                                    <img src={`../../${data && data[0].image}`} alt="Image" />
                                </div>
                                <div className="single-product-post product-detail m-b30">
                                    <div className="sx-post-meta ">
                                        <ul>
                                            <li className="post-date"><i className="fa fa-calendar" /> {new Date(data && data[0].created).toLocaleDateString()} </li>
                                        </ul>
                                    </div>
                                    <div className="sx-post-title ">
                                        <h2 className="post-title">{data && data[0].title}</h2>
                                    </div>
                                    <div className='sx-post-text'>
                                        <div dangerouslySetInnerHTML={{ __html: data && data[0].description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 sticky_column">
                            <div className="side-bar sx-bg-white">
                                <div className="widget widget_services ">
                                    <h4 className="widget-title">Categories</h4>
                                    <ul className="bg-white">
                                        {cat && cat.map((item, index) => {
                                            return (
                                                <li key={index}><Link to={`/cat/${item.id}`}>{item.name}</Link></li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="widget  recent-posts-entry">
                                    <h4 className="widget-title  ">Latest Products</h4>
                                    <div className="section-content">
                                        {product && product.map((item, index) => {
                                            return (
                                                <div className="widget-post-bx" key={index}>
                                                    <div className="widget-post clearfix">
                                                        <div className="sx-post-media">
                                                            <img src={`../../${item.image}`} alt />
                                                        </div>
                                                        <div className="sx-post-info p-t30">
                                                            <div className="sx-post-meta ">
                                                                <ul>
                                                                    <li className="post-date"><i className="fa fa-calendar" />{new Date(item.created).toLocaleDateString()}</li>
                                                                </ul>
                                                            </div>
                                                            <div className="sx-post-title ">
                                                                <h4 className="post-title">
                                                                    <Link to={`/product/${item.id}`} onClick={() => { localStorage.setItem('page', 'products'); window.scrollTo(0, 0); }} >
                                                                        {item.title}
                                                                    </Link>
                                                                </h4>
                                                            </div>
                                                            <div className="sx-post-readmore">
                                                                <Link to={`/product/${item.id}`} onClick={() => { localStorage.setItem('page', 'products'); window.scrollTo(0, 0); }} title="READ MORE" rel="bookmark" className="site-button-link icon"><i className="fa  fa-long-arrow-right" />Read More</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="widget widget_social_icon">
                                    <h4 className="widget-title">Social Icon</h4>
                                    <div className="sx-social-icons ">
                                        <ul className="d-flex">
                                            <li><a href={general && general[0].fb_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook" /></a></li>
                                            <li><a href={general && general[0].ig_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" /></a></li>
                                            <li><a href={general && general[0].twitter_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter" /></a></li>
                                            <li><a href={general && general[0].linkedin_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" /></a></li>
                                            <li><a href={general && general[0].youtube_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;