import { createBrowserRouter } from "react-router-dom";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import ErrorPage from "./components/Pages/404";
import Home from "./components/Pages/Home";
import Products from "./components/Pages/Products"
import ProductDetail from "./components/Pages/ProductDetail";
import ContactUs from "./components/Pages/Contact";
import AboutUs from "./components/Pages/About";
import Service from "./components/Pages/Service";
import Blog from "./components/Pages/Blog";
import BlogDetail from "./components/Pages/BlogDetail";
import Staffs from "./components/Pages/Staff";
import Navbar from "./components/Dashboard/Layout/Navbar";
// dashboard
import Siebar from "./components/Dashboard/Layout/Sidebar";
import DFooter from "./components/Dashboard/Layout/Footer";
import DProducts from "./components/Dashboard/Pages/Products";
import DContact from "./components/Dashboard/Pages/Contact";
import DAbout from "./components/Dashboard/Pages/About";
import DServices from "./components/Dashboard/Pages/Services";
import DStaff from "./components/Dashboard/Pages/Staff";
import DBlog from "./components/Dashboard/Pages/Blog";
import DCategories from "./components/Dashboard/Pages/Categories";
import DClients from "./components/Dashboard/Pages/Clients";
import DSliders from "./components/Dashboard/Pages/Sliders";
import Users from "./components/Dashboard/Pages/Users";
import General from "./components/Dashboard/Pages/General";
import Login from "./components/Dashboard/Pages/Login";




const router = createBrowserRouter([
    {
        path: "/", element:
            <div className="page-wraper">
                <Header />
                <Home />
                <Footer />
            </div >, errorElement: < ErrorPage />,
    },
    {
        path: "/login", element:
            <div className="main-wrapper">
                <Login />
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/products", element:
            <div className="page-wraper">
                <Header />
                <Products />
                <Footer />
            </div >, errorElement: < ErrorPage />,
    },
    {
        path: "/product/:id", element:
            <div className="page-wraper">
                <Header />
                <ProductDetail />
                <Footer />
            </div >, errorElement: < ErrorPage />,
    },
    {
        path: "/contact-us", element:
            <div className="page-wraper">
                <Header />
                <ContactUs />
                <Footer />
            </div >, errorElement: < ErrorPage />,
    },
    {
        path: "/about-us", element:
            <div className="page-wraper">
                <Header />
                <AboutUs />
                <Footer />
            </div >, errorElement: < ErrorPage />,
    },
    {
        path: "/service", element:
            <div className="page-wraper">
                <Header />
                <Service />
                <Footer />
            </div >, errorElement: < ErrorPage />,
    },
    {
        path: "/staffs", element:
            <div className="page-wraper">
                <Header />
                <Staffs />
                <Footer />
            </div >, errorElement: < ErrorPage />,
    },
    {
        path: "/blog", element:
            <div className="page-wraper">
                <Header />
                <Blog />
                <Footer />
            </div >, errorElement: < ErrorPage />,
    },
    {
        path: "/blog/:id", element:
            <div className="page-wraper">
                <Header />
                <BlogDetail />
                <Footer />
            </div >, errorElement: < ErrorPage />,
    },
    // Dashboard
    {
        path: "/dashboard", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <DProducts />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/products", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <DProducts />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/staff", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <DStaff />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/services", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <DServices />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/about", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <DAbout />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/blog", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <DBlog />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/categories", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <DCategories />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/contact", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <DContact />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/clients", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <DClients />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/sliders", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <DSliders />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/users", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <Users />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/dashboard/general", element:
            <div className="main-wrapper">
                <Siebar />
                <div className="page-wrapper">
                    <Navbar />
                    <General />
                    <DFooter />
                </div>
            </div>, errorElement: <ErrorPage />,
    },
]);

export default router;