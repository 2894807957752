import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
const Login = () => {
    function _login(e) {
        e.preventDefault();
        let username = e.target.username.value;
        let password = e.target.password.value;
        axios.post('/login', { username: username, password: password }).then((res) => {
            if (res.data === "err") {
                toast.error("Username Or Password Incorrect !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            } else {
                window.location.href = '/dashboard';
            }
        })
    }
    return (
        <div className="page-wrapper full-page">
            <div className="page-content d-flex align-items-center justify-content-center">
                <div className="row w-100 mx-0 auth-page">
                    <div className="col-md-8 col-xl-6 mx-auto">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-12 ps-md-0">
                                    <div className="auth-form-wrapper px-4 py-5">
                                        <a href="#" className="noble-ui-logo d-block mb-2">Soruce<span>Teach</span></a>
                                        <h5 className="text-muted fw-normal mb-4">Welcome !</h5>
                                        <form className="forms-sample" onSubmit={_login}>
                                            <div className="mb-3">
                                                <label htmlFor="userEmail" className="form-label">Username</label>
                                                <input type="username" className="form-control" id="username" placeholder="Username" />
                                                <small className="form-text text-muted"><span className="alphabet-alert" style={{ color: 'red' }}></span></small>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="userPassword" className="form-label">Password</label>
                                                <input type="password" className="form-control" id="password" autoComplete="current-password" placeholder="Password" />
                                            </div>
                                            <div>
                                                <button type="submit" className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0">
                                                    Login
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small ltr">
                                        <p className="text-muted mb-1 mb-md-0 ltr">© {new Date().getFullYear()} <a href="/" target="_blank" rel="noreferrer">Source Teach</a>.</p>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;