import useGet from '../Hooks/UseGet';
const AboutUs = () => {
    const { data: data } = useGet('/about');
    return (<>
        <div className="full-landing-image sx-bnr-inr overlay-wraper  bg-top-center" style={{ backgroundImage: 'url(../../../assets/images/banner/bnr-7.jpg)' }}>
            <div className="overlay-main bg-white opacity-0" />
            <div className="container">
                <div className="sx-bnr-inr-entry">
                    <div className="banner-title-outer">
                        <div className="banner-title-name">
                            <span className="sx-bnr-sm-title">we are Dedicated</span>
                            <h2 className="sx-title">About Us.</h2>
                        </div>
                        <div>
                            <ul className="sx-breadcrumb breadcrumb-style-2">
                                <li><a href="index.html">Home</a></li>
                                <li>About Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sx-bnr-outline-text">
                <h1> It Solution</h1>
            </div>
        </div>
        <div className="section-full  p-t110 p-b80 sx-bg-white sx-about-bx1-outer">
            <div className="container">
                <div className="section-content">
                    <div className="sx-about-bx1-content-wrap">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="sx-about-bx1-media">
                                    <div className="sx-dot-bx"><img src="../../../assets/images/about/dotted-pic.png" alt="image" /></div>
                                    <div className="sx-ab-img-outer">
                                        <div className="sx-ab-img-section-1-wrap">
                                            <div className="sx-ab-video">
                                                <a href="https://www.youtube.com/watch?v=c1XNqw2gSbU" className="mfp-video circle">
                                                    <i className="flaticon-play" />
                                                </a>
                                            </div>
                                            <div className="sx-ab-img-section-1" style={{ backgroundImage: 'url(../../../assets/images/about/left-pic.png)' }} />
                                        </div>
                                        <div className="sx-ab-img-section-2">
                                            <img src={data && data[0].image} alt="media" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="sx-about-bx1-content">
                                    <h2 className="sx-title">{data && data[0].title}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: data && data[0].description }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default AboutUs;