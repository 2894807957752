import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const DContact = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/contact');
        setData(data);
    };

    useEffect(() => {
        document.title = "Manage Contact | Admin Panel";
        getData();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Contact</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Contact</h6>
                                </div>
                            </div>
                            {data && data.length > 0 ?
                                <div className="col-md-12">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        const formData = new FormData(e.target);
                                        const data = Object.fromEntries(formData);
                                        axios.put(`/contact/1`, data).then(() => {
                                            toast.success('Contact updated successfully');
                                            getData();
                                        })
                                    }} >
                                        <div className="mb-3">
                                            <label htmlFor="phone_1" className="form-label">Phone 1</label>
                                            <input type="text" className="form-control" name="phone_1" defaultValue={data[0].phone_1} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="phone_2" className="form-label">Phone 2</label>
                                            <input type="text" className="form-control" name="phone_2" defaultValue={data[0].phone_2} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="text" className="form-control" name="email" defaultValue={data[0].email} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="location" className="form-label">Location</label>
                                            <input type="text" className="form-control" name="location" defaultValue={data[0].location} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="office_time_from" className="form-label">Office Time From</label>
                                            <input type="text" className="form-control" name="office_time_from" defaultValue={data[0].office_time_from} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="office_time_to" className="form-label">Office Time To</label>
                                            <input type="text" className="form-control" name="office_time_to" defaultValue={data[0].office_time_to} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="map_embed" className="form-label">Map embed</label>
                                            <textarea className="form-control" name="map_embed" rows={3} defaultValue={data[0].map_embed} />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Save changes</button>
                                    </form>
                                </div>
                                : <div className="col-md-12">
                                    <p> Something went wrong</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default DContact;