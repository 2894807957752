import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import useGet from '../Hooks/UseGet';
import $ from 'jquery';
const apiEndpoints = ['about', 'view_blogs?limit=true', 'categories', 'clients', 'contact', 'general', 'view_products?limit=true', 'services', 'sliders', 'staff', 'users'];

const Home = () => {
    const { data: stats } = useGet(`/stats`);
    const [data, setData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            const req = apiEndpoints.map(endpoint => axios.get(`/${endpoint}`));
            const res = await Promise.all(req);
            const data = {};
            res.forEach((res, index) => {
                data[apiEndpoints[index]] = res.data;
            });
            setData(data);
        };
        fetchData();
        setTimeout(() => {
            window.$('.counter').counterUp({
                delay: 10,
                time: 1000
            });
        }, 1000);

        var swiper = new window.Swiper('.home-slider', {
            loop: false,
            spaceBetween: 30,
            effect: "fade",
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            parallax: true,
            autoplay: {
                delay: 7000,
                disableOnInteraction: true,
            },
        });

        if ($('.sticky-header').length) {
            var sticky = new window.Waypoint.Sticky({
                element: $('.sticky-header')
            })
        }
    }, []);

    return (
        <div className="page-content" >
            <div className="sx-bnr-2-wrap-outer home-slider">
                <div className="swiper-wrapper">
                    {data.sliders && data.sliders.map((item, index) => (
                        <div className="sx-bnr-2-wrap swiper-slide overlay-overlay" style={{ backgroundImage: `url(${item.image})` }} key={index}>
                            <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
                            <div className="container">
                                <div className="sx-bnr-2-content">
                                    <span className="sx-bnr-2-small-title">{item.caption}</span>
                                    <h2 className="sx-bnr-2-large-title">{item.title}</h2>
                                    <div className="sx-bnr-2-info">{item.description}</div>
                                    <div className="sx-bnr-readmore">
                                        <Link to="/about" className="site-button sx-btn-primary icon"> <i className="fa fa-long-arrow-right" />  Free Quote </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="swiper-button-next" />
                <div className="swiper-button-prev" />
            </div>
            <div className="section-full p-t110 p-b80 bg-white sx-about-bx3-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx3-content-wrap">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="sx-about-bx3-media">
                                        <div className="sx-dot-bx1">
                                            <img src="../../../../assets/images/about/dotted-pic2.png" alt="image" />
                                        </div>
                                        <div className="sx-dot-bx2">
                                            <img src="../../../../assets/images/about/dotted-pic2.png" alt="image" />
                                        </div>
                                        <div className="sx-ab3-img-outer">
                                            <div className="sx-ab3-media">
                                                <img src={data.about && data.about[0].image} alt="media" />
                                            </div>
                                        </div>
                                        <div className="sx-ab-circle-box">
                                            <div className="sx-ab-circle-box-content">
                                                <span className="sx-exp">10 Years Experience</span>
                                                <div className="sx-exp-title-outer">
                                                    <span className="sx-exp-title-1">It</span>
                                                    <span className="sx-exp-title-2">Solutions</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="sx-about-bx3-content">
                                        <h2 className="sx-title">{data.about && data.about[0].title}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: data.about && data.about[0].description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hilite-title text-left p-l50 text-uppercase">
                            <strong>About Us</strong>
                        </div>
                        <div className="counter-blocks">
                            <div className="row when-item-four">
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="sx-count">
                                        <h2 className="sx-title">
                                            <span className="sx-cont-before">+</span>
                                            <span className="counter">{stats && stats.staff}</span>
                                        </h2>
                                        <div className="sx-count-info">Our Active Member</div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="sx-count">
                                        <h2 className="sx-title">
                                            <span className="sx-cont-before">+</span>
                                            <span className="counter">{stats && stats.products}</span>
                                        </h2>
                                        <div className="sx-count-info">Our Total Products</div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="sx-count">
                                        <h2 className="sx-title">
                                            <span className="sx-cont-before">+</span>
                                            <span className="counter">{stats && stats.blogs}</span>
                                        </h2>
                                        <div className="sx-count-info">Useful Information</div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="sx-count">
                                        <h2 className="sx-title">
                                            <span className="sx-cont-before">+</span>
                                            <span className="counter">{stats && stats.clients}</span>
                                        </h2>
                                        <div className="sx-count-info">Our Clients</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t110 p-b80 sx-client-logo-1-outer sx-bg-light">
                <div className="container">
                    <div className="section-head center max-900">
                        <div className="sx-head-s-title">Our Clients</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title2">Our happy Clients.</h2>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="client-grid m-b30">
                            <div className="row justify-content-center">
                                {data.clients && data.clients.map((item, index) => (
                                    <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6">
                                        <div className="client-logo-pic">
                                            <img src={item.logo} alt />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sx-div-line" />
            <div className="section-full p-t110 p-b80 sx-bg-white sx-case-study2-outer">
                <div className="container">
                    <div className="large-title-block">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="section-head left">
                                    <div className="sx-head-s-title">Our Products</div>
                                    <div className="sx-head-l-title">
                                        <h2 className="sx-title">Our Special Products.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-content ">
                    <div className="sx-case-study-bx2-wrap">
                        <div className="row m-b30">
                            {data['view_products?limit=true'] && data['view_products?limit=true'].map((item, index) => {
                                return (
                                    <div key={index} className={`col-md-${index === 1 ? 6 : 3}`}>
                                        <div className="sx-case-study-bx2 sx-overlay-effect" style={{ backgroundImage: `url(${item.image})` }}>
                                            <div className="sx-case-media sx-img-overlay">
                                                <Link to={`/product/${item.id}`} className="sx-case-link" onClick={() => { localStorage.setItem('page', 'products'); window.scrollTo(0, 0); }} >
                                                    <i className="flaticon-up-right-arrow" />
                                                </Link>
                                            </div>
                                            <div className="sx-case-info">
                                                <div className="sx-s-title">{item.cat_name}</div>
                                                <Link href={`product/${item.id}`} className="sx-l-title" onClick={() => { localStorage.setItem('page', 'products'); window.scrollTo(0, 0); }} >
                                                    <h2 className="sx-title">{item.title}</h2>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="sx-bnr-readmore text-center">
                            <Link onClick={() => { localStorage.setItem('page', 'products'); window.scrollTo(0, 0); }} to="/products" className="site-button sx-btn-primary icon sx-btn-lg">  <i className="fa fa-long-arrow-right" /> View All </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-service-outer ">
                <div className="container">
                    <div className="section-head center">
                        <div className="sx-head-s-title">Our Services</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">Our Awesome Services</h2>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="sx-icon-bx-wrap">
                            <div className="row">
                                {data.services && data.services.map((item, index) => {
                                    return (
                                        <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms">
                                            <div className="sx-icon-bx-1">
                                                <div className="sx-icon-box-wraper">
                                                    <div className="sx-icon-bx-icon ">
                                                        <span className="sx-text-primary"><i className={item.icon} /></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="sx-tilte">{item.title}</h4>
                                                        <p>{item.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t110 p-b80 sx-bg-white sx-ourteam-outer ">
                <div className="container">
                    <div className="section-head center">
                        <div className="sx-head-s-title">Our Team</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">IT Solution Team Members</h2>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row sx-our-team-section">
                            {data.staff && data.staff.map((item, index) => {
                                return (
                                    <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                                        <div className="our-team-2">
                                            <div className="profile-image">
                                                <img src={item.image} alt />
                                                <div className="icons">
                                                    <a href={item.contact} target="_blank" rel="noopener noreferrer" >
                                                        <i className="fa fa-paper-plane-o" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="figcaption">
                                                <p>{item.position}</p>
                                                <h4 className="name">{item.full_name}</h4>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="sx-about-bx4-media">
                                        <div className="sx-ab4-img-outer">
                                            <div className="sx-ab4-media">
                                                <img src={data.general && data.general[0].why_us_image} alt="media" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="sx-about-bx4-content">
                                        <h2 className="sx-title"> {data.general && data.general[0].why_us_title} </h2>
                                        <div dangerouslySetInnerHTML={{ __html: data.general && data.general[0].why_us_description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full p-t110 p-b80 bg-white sx-latest-blog-outer ">
                <div className="container">
                    <div className="section-head center">
                        <div className="sx-head-s-title">Our Blogs</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">Our Latest News</h2>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row">
                            {data['view_blogs?limit=true'] && data['view_blogs?limit=true'].map((item, index) => {
                                return (
                                    <div className="col-md-4" key={index}>
                                        <div className="sx-latest-post-st-1">
                                            <div className="sx-post-media sx-img-effect img-reflection">
                                                <Link to={`/blog/${item.id}`} onClick={() => { localStorage.setItem('page', 'blog'); window.scrollTo(0, 0); }} >
                                                    <img src={item.image} alt />
                                                </Link>
                                            </div>
                                            <div className="sx-post-info p-t30">
                                                <div className="sx-post-meta ">
                                                    <ul>
                                                        <li className="post-date">
                                                            <Link to={`/blog/${item.id}`} onClick={() => { localStorage.setItem('page', 'blog'); window.scrollTo(0, 0); }} >
                                                                <i className="fa  fa-calendar" />
                                                                {new Date(item.created).toDateString()}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="sx-post-title " onClick={() => { localStorage.setItem('page', 'blog'); window.scrollTo(0, 0); }} >
                                                    <h4 className="post-title">
                                                        <Link to={`/blog/${item.id}`}>
                                                            {item.title}
                                                        </Link>
                                                    </h4>
                                                </div>
                                                <div className="sx-post-readmore">
                                                    <Link to={`/blog/${item.id}`} title="READ MORE" rel="bookmark" className="site-button icon" onClick={() => { localStorage.setItem('page', 'blog'); window.scrollTo(0, 0); }} >
                                                        <i className="fa fa-long-arrow-right" />Read More
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;