import { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { toast } from "react-toastify";

const DAbout = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/about');
        setData(data);
    };

    const uploadImage = async (id, file) => {
        const formData = new FormData();
        formData.append('file', file);
        const res = await axios.post('/upload', formData);
        if (res.data !== 'err') {
            $(`#image${id}`).val(res.data);
            $(`#upimage${id}`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg${id}" alt=${res.data} />`);
        }
    };

    const deleteImage = (id, alt) => {
        $(`.viewImg${id}`).remove();
        axios.delete(`/upload/${alt}`).then(response => {
            if (response.data !== 'err') {
                $(`#image${id}`).val('');
            }
        });
    };

    const handleImageChange = (id) => {
        $('<input type="file" accept="image/*" />').click().on('change', async (event) => {
            await uploadImage(id, event.target.files[0]);
        });
    };

    useEffect(() => {
        document.title = "Manage About | Admin Panel";
        getData();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">About</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">About Wesbiste</h6>
                                </div>
                            </div>
                            {data && data.length > 0 ?
                                <div className="col-md-12">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        const formData = new FormData(e.target);
                                        const data = Object.fromEntries(formData);
                                        axios.put(`/about/1`, data).then(() => {
                                            toast.success('About updated successfully');
                                            getData();
                                        })
                                    }} >
                                        <div className="mb-3">
                                            <label htmlFor="title" className="form-label">Title</label>
                                            <input type="text" className="form-control" name="title" defaultValue={data[0].title} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label">Description</label>
                                            <textarea className="form-control" name="description" rows={3} defaultValue={data[0].description} />
                                        </div>
                                        <div className="mb-3">
                                            <label>Image</label>
                                            <input type="hidden" name="image" id={`image${data[0].id}`} defaultValue={data[0].image} />
                                            <div className="form-control text-center" onClick={() => handleImageChange(data[0].id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-upload">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="17 8 12 3 7 8"></polyline>
                                                    <line x1="12" y1="3" x2="12" y2="15"></line>
                                                </svg>
                                            </div>
                                            <br />
                                            <div align="center" id={`upimage${data[0].id}`}>
                                                {data[0].image && <img src={`../../../${data[0].image}`} className={`img-thumbnail viewImg${data[0].id}`} alt={data[0].image} onClick={() => deleteImage(data[0].id, data[0].image)} />}
                                            </div>
                                        </div>

                                        <button type="submit" className="btn btn-primary">Save changes</button>
                                    </form>
                                </div>
                                : <div className="col-md-12">
                                    <p> Something went wrong</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default DAbout;