import { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const DStaff = () => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/staff');
        setData(data);
    };

    const uploadImage = async (id, file) => {
        const formData = new FormData();
        formData.append('file', file);
        const res = await axios.post('/upload', formData);
        if (res.data !== 'err') {
            $(`#image${id}`).val(res.data);
            $(`#upimage${id}`).append(`<img src="../../../${res.data}" class="img-thumbnail viewImg${id}" alt=${res.data} />`);
        }
    };

    const deleteImage = (id, alt) => {
        $(`.viewImg${id}`).remove();
        axios.delete(`/upload/${alt}`).then(response => {
            if (response.data !== 'err') {
                $(`#image${id}`).val('');
            }
        });
    };

    const handleImageChange = (id) => {
        $('<input type="file" accept="image/*" />').click().on('change', async (event) => {
            await uploadImage(id, event.target.files[0]);
        });
    };

    useEffect(() => {
        document.title = "Manage Staff | Admin Panel";
        getData();
    }, []);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Staff</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Staff List</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                        <span className="ms-2">New Member</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">New Memeber</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/staff`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('Memeber Added successfully');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="full_name" className="form-label">Full Name</label>
                                                            <input type="text" className="form-control" name="full_name" placeholder="full name ..." />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="position" className="form-label">Position</label>
                                                            <input type="text" className="form-control" name="position" placeholder="Position ..." />
                                                        </div>
                                                        <div classNaproductsme="mb-3">
                                                            <label>Image</label>
                                                            <input type="hidden" name="image" id={`image0`} placeholder="Image ..." />
                                                            <div className="form-control text-center" onClick={() => handleImageChange(0)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-upload">
                                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                                    <polyline points="17 8 12 3 7 8"></polyline>
                                                                    <line x1="12" y1="3" x2="12" y2="15"></line>
                                                                </svg>
                                                            </div>
                                                            <br />
                                                            <div align="center" id={`upimage0`}>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor="contact" className="form-label">Contact Link/URL</label>
                                                            <input type="text" className="form-control" name="contact" placeholder="contact ..." />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Save changes</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table id="dataTableExample" className="table dt-tbl">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Position</th>
                                            <th>Created</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td><img src={`../../${item.image}`} alt={item.title} className="img-fluid" /></td>
                                                    <td>{item.full_name}</td>
                                                    <td>{item.position}</td>
                                                    <td>{new Date(item.created).toLocaleString()}</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                        </button>
                                                        <button className="btn btn-sm btn-outline-danger"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: 'Warning!',
                                                                    text: "Are you sure you want to delete this Memeber?",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: 'Yes, delete it!',
                                                                    cancelButtonText: 'No, cancel!'
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        axios.delete(`/staff/${item.id}`).then(() => {
                                                                            getData();
                                                                        })
                                                                    }
                                                                })
                                                            }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                        </button>
                                                    </td>
                                                    <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-lg">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="exampleModalLabel">Edit Post</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                </div>
                                                                <form onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    const data = Object.fromEntries(formData);
                                                                    axios.put(`/staff/${item.id}`, data).then(() => {
                                                                        window.$(`#edit${item.id}`).modal('hide');
                                                                        toast.success('Memeber updated successfully');
                                                                        getData();
                                                                    })
                                                                }} >
                                                                    <div className="modal-body">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="full_name" className="form-label">Full Name</label>
                                                                            <input type="text" className="form-control" name="full_name" defaultValue={item.full_name} />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="position" className="form-label">Position</label>
                                                                            <input type="text" className="form-control" name="position" defaultValue={item.position} />
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label>Image</label>
                                                                            <input type="hidden" name="image" id={`image${item.id}`} defaultValue={item.image} />
                                                                            <div className="form-control text-center" onClick={() => handleImageChange(item.id)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-upload">
                                                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                                                    <polyline points="17 8 12 3 7 8"></polyline>
                                                                                    <line x1="12" y1="3" x2="12" y2="15"></line>
                                                                                </svg>
                                                                            </div>
                                                                            <br />
                                                                            <div align="center" id={`upimage${item.id}`}>
                                                                                {item.image && <img src={`../../../${item.image}`} className={`img-thumbnail viewImg${item.id}`} alt={item.image} onClick={() => deleteImage(item.id, item.image)} />}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label htmlFor="contact" className="form-label">Contact Link/URL</label>
                                                                            <input type="text" className="form-control" name="contact" defaultValue={item.contact} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                        <button type="submit" className="btn btn-primary">Save changes</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default DStaff;