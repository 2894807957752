import { useState, useEffect } from 'react'
const useGet = (url) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetch(url).then(res => res.json()).then(json => {
            setData(json);
        }).catch(error => {
            setError(error);
        });
    }, [url]);
    return { data, error };
}

export default useGet;