import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";


const Navbar = () => {

    const logout = () => {
        axios.post('/logout').then((ok) => {
            if (ok.data !== 'err') {
                window.location.assign("/")
            } else {
                window.location.reload()
            }
        })
    }
    const [user, setUser] = useState(null);
    useEffect(() => {
        axios.get("/auth").then(res => {
            if (res.data !== "err") {
                setUser(res.data[0]);
            }
        })
        $(document).on('click touchstart', function (e) {
            e.stopPropagation();
            if (!$(e.target).closest('.sidebar-toggler').length) {
                var sidebar = $(e.target).closest('.sidebar').length;
                var sidebarBody = $(e.target).closest('.sidebar-body').length;
                if (!sidebar && !sidebarBody) {
                    if ($('body').hasClass('sidebar-open')) {
                        $('body').removeClass('sidebar-open');
                    }
                }
            }
        });

        $(window).scroll(function () {
            if (window.matchMedia('(min-width: 992px)').matches) {
                var header = $('.horizontal-menu');
                if ($(window).scrollTop() >= 60) {
                    $(header).addClass('fixed-on-scroll');
                } else {
                    $(header).removeClass('fixed-on-scroll');
                }
            }
        });
        $('.sidebar .sidebar-body').hover(function () {
            $('body').addClass('overflow-hidden');
        }, function () {
            $('body').removeClass('overflow-hidden');
        });
    }, []);

    return (
        <nav className="navbar">
            <a href="/dashboard" className="sidebar-toggler" onClick={(e) => {
                $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                if (window.matchMedia('(min-width: 992px)').matches) {
                    $('body').toggleClass('sidebar-folded');
                } else if (window.matchMedia('(max-width: 991px)').matches) {
                    $('body').toggleClass('sidebar-open');
                }
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
            </a>
            <div className="navbar-content">
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <p className="nav-link dropdown-toggle" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                        </p>
                        <div className="dropdown-menu p-0" aria-labelledby="profileDropdown">
                            <div className="d-flex flex-column align-items-center border-bottom px-5 py-3">
                                <div className="mb-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                                </div>
                                <div className="text-center">
                                    <p className="tx-16 fw-bolder">{user && user.full_name}</p>
                                    <p className="tx-16 text-muted">@{user && user.username}</p>
                                </div>
                            </div>
                            <ul className="list-unstyled p-1">
                                <li className="dropdown-item py-2">
                                    <Link to={`/dashboard/users`} className="text-body ms-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                        <span className="mx-2">Edit Account</span>
                                    </Link>
                                </li>
                                <li className="dropdown-item py-2">
                                    <a href="/" className="text-body ms-0" onClick={(e) => {
                                        e.preventDefault();
                                        logout();
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1={21} y1={12} x2={9} y2={12} /></svg>
                                        <span className="mx-2">Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;