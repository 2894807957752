import useGet from '../Hooks/UseGet';
const Service = () => {
    const { data: data } = useGet('/services');
    return (
        <>
            <div>
                <div className="full-landing-image sx-bnr-inr overlay-wraper  bg-top-center" style={{ backgroundImage: 'url(../../../assets/images/banner/bnr-7.jpg)' }}>
                    <div className="overlay-main bg-white opacity-0" />
                    <div className="container">
                        <div className="sx-bnr-inr-entry">
                            <div className="banner-title-outer">
                                <div className="banner-title-name">
                                    <span className="sx-bnr-sm-title">we are Dedicated</span>
                                    <h2 className="sx-title">Our Services</h2>
                                </div>
                                <div>
                                    <ul className="sx-breadcrumb breadcrumb-style-2">
                                        <li><a href="index.html">Home</a></li>
                                        <li>Our Services.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sx-bnr-outline-text">
                        <h1> It Solution</h1>
                    </div>
                </div>
                <div className="section-full p-t110 p-b80 sx-why-chooseus-outer sx-bg-light bg-cover" style={{ backgroundImage: 'url(../../../assets/images/background/bg-1.jpg)' }}>
                    <div className="container">
                        <div className="section-head center">
                            <div className="sx-head-s-title">Why Choose Us</div>
                            <div className="sx-head-l-title">
                                <h2 className="sx-title">Our Work Services</h2>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="sx-icon-bx-wrap">
                                <div className="row">
                                    {data && data.map((item, index) => {
                                        return (
                                            <div className="col-lg-4 col-md-6 wow fadeInDown" data-wow-duration="1000ms" key={index}>
                                                <div className="sx-icon-bx-1">
                                                    <div className="sx-icon-box-wraper">
                                                        <div className="sx-icon-bx-icon ">
                                                            <span className="sx-text-primary"><i className={item.icon} /></span>
                                                        </div>
                                                        <div className="icon-content">
                                                            <h4 className="sx-tilte">{item.title}</h4>
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Service;