import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useGet from '../Hooks/UseGet';
import $ from 'jquery';
const Footer = () => {
    const { data: general } = useGet(`/general`);
    const { data: contact } = useGet(`/contact`);
    useEffect(() => {
        $(window).on('resize', function () {
            $('.site-footer').css('display', 'block');
            $('.site-footer').css('height', 'auto');
            var footerHeight = $('.site-footer').outerHeight();
            $('.footer-fixed > .page-wraper').css('padding-bottom', footerHeight);
            $('.site-footer').css('height', footerHeight);
        }).resize();
    }, []);
    return (
        <footer className="site-footer footer-dark">
            <div className="sx-f-nl-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h2 className="sx-f-title">
                                You too join my communities.
                            </h2>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="sx-nl-form">
                                <div className="sx-nl-form-inner">
                                    <input type="text" className="sx-nl-form-input" placeholder={contact && contact[0].email} />
                                    <button className="sx-nl-form-btn"><i className="flaticon-back-left" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6">
                            <div className="widget widget_about">
                                <div className="logo-footer clearfix p-b15">
                                    <a href="index.html" className="sx-text-primary"><img src={`../../${general && general[0].logo}`} alt="image" /></a>
                                </div>
                                <p className='w-75'>{general && general[0].about_website} </p>
                                <div className="widget_about-call-section">
                                    <div className="sx-f-call-icon"><i className="flaticon-telephone" /></div>
                                    <div className="sx-f-call-section">
                                        <span>Contact us 24/7</span>
                                        <a href={`tel:${contact && contact[0].phone_1}`}>{contact && contact[0].phone_1}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6 col">
                            <div className="widget widget_services">
                                <h4 className="sx-f-title">Explore Links</h4>
                                <ul>
                                    <li id="home" onClick={() => { localStorage.setItem('page', 'home'); window.scrollTo(0, 0); }}>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li id="about-us" onClick={() => { localStorage.setItem('page', 'about-us'); window.scrollTo(0, 0); }}>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li id="contact-us" onClick={() => { localStorage.setItem('page', 'contact-us'); window.scrollTo(0, 0); }}>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6 col">
                            <div className="widget widget_services">
                                <h4 className="sx-f-title">Useful Links</h4>
                                <ul>
                                    <li id="blog" onClick={() => { localStorage.setItem('page', 'blog'); window.scrollTo(0, 0); }}>
                                        <Link to="/blog">Blog</Link>
                                    </li>
                                    <li id="staffs" onClick={() => { localStorage.setItem('page', 'staffs'); window.scrollTo(0, 0); }}>
                                        <Link to="/staffs">Staff</Link>
                                    </li>
                                    <li id="products" onClick={() => { localStorage.setItem('page', 'products'); window.scrollTo(0, 0); }}>
                                        <Link to="/products">Products</Link>
                                    </li>
                                    <li id="service" onClick={() => { localStorage.setItem('page', 'service'); window.scrollTo(0, 0); }}>
                                        <Link to="/service">Service</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 col footer-col-3">
                            <div className="widget widget_info">
                                <h4 className="sx-f-title">Solutions</h4>
                                <ul>
                                    <li>
                                        <div className="widget-info-icon">
                                            <i className="flaticon-phone" />
                                        </div>
                                        <div className="widget-info-detail">
                                            <a href={`tel:${contact && contact[0].phone_1}`}>{contact && contact[0].phone_1}</a>
                                            <a href={`tel:${contact && contact[0].phone_2}`}>{contact && contact[0].phone_2}</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="widget-info-icon">
                                            <i className="flaticon-email" />
                                        </div>
                                        <div className="widget-info-detail">
                                            <p>{contact && contact[0].email}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="widget-info-icon">
                                            <i className="flaticon-maps-and-flags" />
                                        </div>
                                        <div className="widget-info-detail">
                                            <p>{contact && contact[0].location}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sx-f-bottom-section">
                <div className="sx-f-social">
                    <ul>
                        <li><a href={general && general[0].fb_link} target="_blank" rel="noopener noreferrer"><i className="flaticon-facebook" /></a></li>
                        <li><a href={general && general[0].ig_link} target="_blank" rel="noopener noreferrer"><i className="flaticon-instagram" /></a></li>
                        <li><a href={general && general[0].twitter_link} target="_blank" rel="noopener noreferrer"><i className="flaticon-twitter" /></a></li>
                        <li><a href={general && general[0].linkedin_link} target="_blank" rel="noopener noreferrer"><i className="flaticon-linkedin" /></a></li>
                        <li><a href={general && general[0].youtube_link} target="_blank" rel="noopener noreferrer"><i className="flaticon-youtube" /></a></li>
                    </ul>
                </div>
                <div className="sx-f-copy"> ©  {new Date().getFullYear()} by <a href="/">SoruceTeach.</a> All rights reserved.</div>
            </div>
        </footer>
    );
}

export default Footer;